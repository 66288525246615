import image1 from '../../../../assets/images/projects/momentum/app/1.png';
import image2 from '../../../../assets/images/projects/momentum/app/2.png';
import image3 from '../../../../assets/images/projects/momentum/app/3.png';
import image4 from '../../../../assets/images/projects/momentum/app/4.png';
import image5 from '../../../../assets/images/projects/momentum/app/5.png';
import image6 from '../../../../assets/images/projects/momentum/app/6.png';
import image7 from '../../../../assets/images/projects/momentum/app/7.png';

const MomentumApp = () => (
    <section className="projects-momentum-app">
        <div className="projects-momentum-app-box projects-momentum-app-box-a">
            <h3 className="h3-green-logo">Wireframes</h3>
            <div className="column-paragraph-container">
                <p>I decide to place the calendar in the central place, beneath the ‘hero’ welcoming, the calendar will show the present week and the workouts scheduled. </p>
                <p>From this point, the user will continue easily to the first and second task flow. add and select a workout, and the second point, helping the user to set a review for any exercise to enhance his improvement</p>
            </div>
        </div>
        <div className="projects-momentum-app-images-box">
            <div className="projects-momentum-app-box">
                <img src={image1} alt="look at the app" />
            </div>
                <div className="projects-momentum-app-box projects-momentum-app-box-b">
                <h3 className="h3-green-logo">Design</h3>
                <p>Design research is required to move from wireframes to designed frames. I am trying to identify the value of the users, their interests, Observing also other products on the market, and see which style is fit for the app theme.</p>
            </div>
            <img src={image2} alt="look at the app" />
            <img src={image3} alt="look at the app" />
            <div className="projects-momentum-app-image-box-push-from-left">
                <img src={image4} alt="look at the app" />
            </div>
            <div className="projects-momentum-app-image-box-push-from-right">
                <img src={image5} alt="look at the app" />
            </div>
            <div className="projects-momentum-app-image-box-push-from-left">
                <img src={image6} alt="look at the app" />
            </div>
            <div className="projects-momentum-app-image-box-push-from-right">
                <img src={image7} alt="look at the app" />
            </div>
        </div>
    </section>
)

export default MomentumApp;