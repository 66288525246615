import paymeTopImage from '../../../../assets/images/projects/payme/projects-payme-intro-ipad.png';
import mailboxImage from '../../../../assets/images/projects/payme/mailbox.png';
import avatarImage from '../../../../assets/images/projects/payme/avatar.png';
import taskflow1Image from '../../../../assets/images/projects/payme/taskflow-1.svg';
import taskflow2Image from '../../../../assets/images/projects/payme/taskflow-2.svg';
import flowchartImage from '../../../../assets/images/projects/payme/flowchart.svg';

const PaymeIdea = () => (
    <>
    <section className="projects-payme-idea">
        <div className="projects-payme-idea-top">
            <div>
                <h2>Payme</h2>
                <p>An online bill payment platform</p>
            </div>
            <img src={paymeTopImage} alt="Payme Introduction" />
        </div>
        <div className="projects-payme-idea-box projects-payme-idea-box-a">
            <ul className="row">
                <li>
                    <h3 className="h3-blue-logo">Overview</h3>
                    <p>
                    This exercise was conducted as part of my UX<br />
                    studies at Codesigner, The project included<br />
                    interviews, preliminary studies etc’
                    </p>
                </li>
                <li>
                    <h3 className="h3-blue-logo">My role</h3>
                    <p>
                    User research and competitive analysis,<br />
                    information architecture, user flow, MVP definition,<br />
                    wireframes, and UI design.
                    </p>
                </li>
            </ul>
        </div>
        <div className="projects-payme-idea-box projects-payme-idea-box-b green">
            <div className="projects-payme-user-research">
                <h3 className="h3-blue-logo">User Research</h3>
                <p>
                I have made a online interviews with<br />
                Four potential users from my target audience.<br />
                Here are some conclusions - 
                </p>
            </div>
            <img src={mailboxImage} alt="mailbox" />
        </div>
        <div className="projects-payme-idea-box projects-payme-idea-box-c green">
            <div className="projects-payme-idea-user-research">
                <h3 className="h3-blue-logo">Conclusions</h3>
                <p>
                Currently, most infrastructure providers send  invoices for<br />
                payment through the mail. resulting in  several major issues.<br />
                Common issues are - 
                </p>
            </div>
            <ul className="row">
                <li>
                    <h2>01</h2>
                    <p>
                    difficulty in <b>collecting</b> and <b>tracking</b> bill payments
                    </p>
                </li>
                <li>
                    <h2>02</h2>
                    <p>
                    Users report that they sometimes <b>forget and miss</b> the payment date, incurring fees
                    </p>
                </li>
                <li>
                    <h2>03</h2>
                    <p>
                    Difficulty in managing and tracking monthly expenses due to the physical filing of the invoices and payment receipts 
                    </p>
                </li>
            </ul>
        </div>
        <div className="projects-payme-idea-box projects-payme-idea-box-d">
            <ul className="row">
                <li>
                    <h3 className="h3-blue-logo">Insights</h3>
                    <p>
                    There is also an expectation that came up through the cracks  - <br />
                    a digital platform that centralizes the whole process from<br />
                    receiving an invoice through payment. This platform will make<br />
                    the payment process significantly easier. Furthermore, it will be<br />
                    possible to avoid fines and to control payments
                    </p>
                </li>
                <li>
                    <div className="projects-payme-idea-box-d-value-proposition">
                        <h3 className="h3-blue-logo">Value proposition</h3>
                        <p className="text-green">
                        <b>
                        Payme is a platform that brings<br />
                        together the entire process of paying<br />
                        bills. Payme contains an alert center, an<br />
                        efficient payment mechanism, and an<br />
                        invoice archive, as well as<br />
                        graphs,charts, and analysis tools to help<br />
                        users stay on top of their bills
                        </b>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div className="last-in-section"></div>
    </section>
    <section className="projects-payme-analyzing">
        <div className="projects-payme-analyzing-box projects-payme-analyzing-box-a">
            <div className="projects-payme-analyzing-persona">
                <h3 className="h3-blue-logo">Persona</h3>
                <blockquote className="projects-payme-analyzing-persona-quote">
                "I get all the payments in my mailbox outside and<br />
                sometimes <span className="text-green">they disappear</span> or get wet, This outdated way of<br />
                doing things makes me <span className="text-green">late on my payments</span> and<br />
                sometimes I even get fined for it".
                </blockquote>
            </div>
        </div>
        <div className="projects-payme-analyzing-box projects-payme-analyzing-box-b">
            <div className="projects-payme-analyzing-arik-info">
                <div className="projects-payme-analyzing-arik-info-grid-a">
                    <h3>Background</h3>
                    <p>
                    Arik works every day until noon and then frees up his
                    time to spend time with his kids. He makes sure to collect
                    all the bills from the mailbox and tries to pay them on
                    time. It keeps track of its expenses on a computer with
                    the bank and credit app 
                    </p>
                </div>
                <div className="projects-payme-analyzing-arik-info-grid-b">
                    <table>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Age</th>
                                <th>Personal status</th>
                            </tr>
                            <tr>
                                <td>Arik levi</td>
                                <td>55</td>
                                <td>Customer Relations Manager</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="projects-payme-analyzing-arik-info-grid-c">
                    <img src={avatarImage} alt="portrait of arik levi" />
                </div>
                <div className="projects-payme-analyzing-arik-info-grid-d">
                    <h3>Frustrations</h3>
                    <p>
                    Even though some of the accounts have already been transferred to
                    email, many people still receive bills in their mailboxes. As a result,
                    they find it hard to keep track of expenses, are unable to pay on time,
                    and sometimes even lose their bills entirely. Each provider has their
                    own payment method which requires separate registration, leaving
                    users mired in many companies’ bureaucratic systems. It is not
                    possible to compare accounts without doing the process manually.
                    </p>
                </div>
                <div className="projects-payme-analyzing-arik-info-grid-e">
                    <h3>Goals</h3>
                    <p>
                    The site will offer the possibility of paying
                    all bills in one place to allow greater
                    control over one’s expenses. The site will
                    also be able to remind users about
                    future payments.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section className="projects-payme-flowchart">
        <div className="projects-payme-flowchart-box projects-payme-flowchart-box-a">
            <h3 className="h3-blue-logo">Primary task flow</h3>
            <div>
                <h4>Primary - Receiving a payment notice and making the payment</h4>
                <img src={taskflow1Image} alt="primary taskflow" />
            </div>
            <div>
                <h4>Secondery - Review and track expenses</h4>
                <img src={taskflow2Image} alt="secondary taskflow" />
            </div>
        </div>
        <div className="last-in-section"></div>
        <div className="projects-payme-flowchart-box projects-payme-flowchart-box-b">
            <h3 className="h3-blue-logo">User flow</h3>
            <div>
            <img src={flowchartImage} alt="User flow" />
            </div>
        </div>
        <div className="last-in-section"></div>
    </section>
    </>
)

export default PaymeIdea;