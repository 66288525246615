import { Link } from 'react-router-dom';

import socialLinkedin from '../../assets/images/social/linkedin.png';
import socialInstagram from '../../assets/images/social/instagram.png';

const About = () => (
    <div className="about container-space">
        <h2>What makes<br />you stand<br />out as a<br /><span className="red">Designer ?</span></h2>
        <div className="about-words">
            <div className="column-paragraph-container">
                <p>My name is Ronen Televich and I’m a Product designer lives in Jaffa and is a first-time dad. I'm passionate about life in general but especially about design, people, and the fusion of both of them.</p>
                <p>I graduated successfully in the <b>Industrial design</b> department in  Holon institute and thought about how could I deepen my  knowledge and develop further in the design field.</p>
                <p>The user experience field is not new to me because even in  the development of a physical product there is a significant  relationship with the user who is at the center of the product.</p>
                <p>I'm a fast learner so I chose to study professionally in the field of  User Experience and I have graduated UX design diploma at  Codesigner.</p>
                <p>Hungry to be the best. To contribute and make changes</p>
            </div>
            <div className="about-buttons">
                <Link to="/files/Ronen televich_CV.pdf" className="about-download-button" target="_blank" download>Download CV</Link>
                <div className="about-social-buttons">
                    <a href="https://il.linkedin.com/in/ronen-brons-televich" rel="noreferrer" target="_blank">
                        <img src={socialLinkedin} alt="LinkedIn" />
                    </a>
                    <a href="https://instagram.com/televich_brons?igshid=YmMyMTA2M2Y=" rel="noreferrer"  target="_blank">
                        <img src={socialInstagram} alt="Instagram" />
                    </a>
                </div>
            </div>
        </div>
    </div>
)

export default About;