import touristaTopImage from '../../../../assets/images/projects/tourista/projects-tourista-intro-ipad.png';
import line from '../../../../assets/images/projects/tourista/projects-tourista-line.svg';

const TouristaIdea = () => (
    <section className="projects-tourista-idea">
        <div className="projects-tourista-idea-top">
            <div>
                <h2>Tourista</h2>
                <p>Tourist agency</p>
            </div>
            <img src={touristaTopImage} alt="Tourista Introduction" />
        </div>
        <div className="projects-tourista-idea-box projects-tourista-idea-box-a">
            <ul className="row">
                <li>
                    <h3 className="h3-purple-logo">Overview</h3>
                    <p>
                    As part of a home Design Exercise, I characterized and<br />
                    designed a dashboard system for an internet tourism<br />
                    company, which offers customized packages according to<br />
                    user preferences. The packages include hotel flights and<br />
                    attractions 
                    </p>
                </li>
                <li>
                    <h3 className="h3-purple-logo">My role</h3>
                    <p>
                    Market & competitive analysis,<br />
                    information architecture, user flow and design.
                    </p>
                </li>
            </ul>
        </div>
        <div className="projects-tourista-idea-box projects-tourista-idea-box-b">
            <div className="idea-box-inside-container light-blue">
                <ul className="row">
                    <li>
                        <h3 className="h3-purple-logo">The brief</h3>
                        <div className="column-paragraph-container">
                            <p>
                            Tourista decided they wanted to create a<br />
                            new area on the site where the users can<br />
                            explore all the services that Tourista offers<br />
                            according to their personal preferences.
                            </p>
                        </div>
                    </li>
                    <li>
                        <h3 className="h3-purple-logo">Use case</h3>
                        <p>
                        The user goes to the exploration area on the<br />
                        Tourista website and wants to find a Vacation<br />
                        Package that includes a flight, hotel, and tickets<br />
                        to attractions In the city that he chooses.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div className="projects-tourista-idea-box light-blue projects-tourista-idea-box-c">
            <div className="projects-tourista-idea-market-research">
                <h3 className="h3-purple-logo">Market Research</h3>
                <p>
                I have reviewed several products from relevant categories,<br />
                including hotel, flights and attractions.  As I was required to<br />
                design a dashboard, I wanted to include as much information<br />
                as possible in a minimal style.  In this way, users will be able to<br />
                quickly and easily understand the bigger picture.<br />
                It will also encourage users  to explore and discover more
                </p>
            </div>
            <img src={line} alt="dividing line" className="dividing-line" />
            <div className="projects-tourista-idea-market-research">
                <h3 className="h3-purple-logo">Design</h3>
                <div className="column-paragraph-container">
                    <p>
                    The choice to present a clean and bright website<br />
                    stemmed from the desire to show the colorful<br />
                    richness of travel through photos of various<br />
                    destinations, different attractions, and hotels. In<br />
                    addition, this choice was made out of the need to<br />
                    avoid overloading the user with information by<br />
                    presenting a bright and clean space.
                    </p>
                </div>
            </div>
        </div>
        <div></div>
    </section>
)

export default TouristaIdea;