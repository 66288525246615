import image1 from '../../../../assets/images/projects/payme/app/1.jpg';
import image2 from '../../../../assets/images/projects/payme/app/2.jpg';
import image3 from '../../../../assets/images/projects/payme/app/3.jpg';
import image4 from '../../../../assets/images/projects/payme/app/4.jpg';
import image5 from '../../../../assets/images/projects/payme/app/5.jpg';
import image6 from '../../../../assets/images/projects/payme/app/6.jpg';

const PaymeApp = () => (
    <section className="projects-tourista-app">
        <div className="projects-tourista-app-box projects-tourista-app-box-a">
            <h3 className="h3-blue-logo">Design</h3>
            <p>
            I chose to design a modern and new language for the platform which matches the<br />
            innovation of the experience.  Before this platform was established, information was<br />
            scattered, resulting in forgotton payments and lostpayment messages. In accordance<br />
            with the new experience, I wanted to provide a new and refreshing design language.
            </p>
            <p>
            I chose to use green and blue as key colors that provide security and stability, as befits<br />
            a financial digital platform. Likewise, I choose to design the tabs in a modern style.
            </p>
        </div>
        <div className="projects-tourista-app-images-box">
            <img src={image1} alt="look at the app" />
            <img src={image2} alt="look at the app" />
            <img src={image3} alt="look at the app" />
            <img src={image4} alt="look at the app" />
            <img src={image5} alt="look at the app" />
            <img src={image6} alt="look at the app" />
        </div>
    </section>
)

export default PaymeApp;