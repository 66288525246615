import primaryTaskFlow from '../../../../assets/images/projects/momentum/momentum-primary-task-flow.png';
import secondaryTaskFlow from '../../../../assets/images/projects/momentum/momentum-secondary-task-flow.png';
import userFlow from '../../../../assets/images/projects/momentum/momentum-user-flow.png';

const MomentumFlowchart = () => (
    <section className="projects-momentum-flowchart">
        <div className="projects-momentum-flowchart-box projects-momentum-flowchart-box-a">
        <h3 className="h3-green-logo">Primary task flow</h3>
            <div className="projects-momentum-flowchart-task-flow">
                <ul className="column">
                    <li>
                    <h3>Primary</h3>
                        <img src={primaryTaskFlow} alt="primary flow" />
                    </li>
                    <li>
                        <h3>Secondary</h3>
                        <img src={secondaryTaskFlow} alt="secondary flow" />
                    </li>
                </ul>
            </div>
        </div>
        <div className="projects-momentum-flowchart-box projects-momentum-flowchart-box-b">
        <h3 className="h3-green-logo">User flow</h3>
            <img src={userFlow} alt="user flow" />
        </div>
        <div className="last-in-section"></div>
    </section>
)

export default MomentumFlowchart;