import Momentum from './Sections/Momentum';
import PayMe from './Sections/PayMe';
import Tourista from './Sections/Tourista';
import LetsTalk from './Sections/LetsTalk';

import greetImage from '../../assets/images/home/home-greet.jpg';
import socialLinkedin from '../../assets/images/social/linkedin.png';
import socialInstagram from '../../assets/images/social/instagram.png';

const Home = () => (
    <div className="home container-space">
        <div className="home-greet">
            <main>
                <figure>
                    <div className="home-greet-figure-image">
                        <span className="home-greet-figure-border"></span>
                        <img src={greetImage} alt="Ronen Televich" />
                    </div>
                    <figcaption>
                        <h2>Hello!</h2>
                        <p>
                            My name is Ronen,<br />
                            and I am a product designer.<br />
                            I'm focused on creating meaningful<br />
                            experiences through great design.
                        </p>
                    </figcaption>
                </figure>
            </main>
            <div className="home-greet-social-buttons">
                <a href="https://il.linkedin.com/in/ronen-brons-televich" rel="noreferrer" target="_blank">
                    <img src={socialLinkedin} alt="LinkedIn" />
                </a>
                <a href="https://instagram.com/televich_brons?igshid=YmMyMTA2M2Y=" rel="noreferrer"  target="_blank">
                    <img src={socialInstagram} alt="Instagram" />
                </a>
            </div>
        </div>
        <Momentum />
        <PayMe />
        <Tourista />
        <LetsTalk />
    </div>
)

export default Home;