import image1 from '../../../../assets/images/projects/tourista/app/1.jpg';
import image2 from '../../../../assets/images/projects/tourista/app/2.jpg';
import image3 from '../../../../assets/images/projects/tourista/app/3.jpg';
import image4 from '../../../../assets/images/projects/tourista/app/4.jpg';

const TouristaApp = () => (
    <section className="projects-tourista-app">
        <div className="projects-tourista-app-box projects-tourista-app-box-a">
            <h3 className="h3-purple-logo">Design</h3>
        </div>
        <div className="projects-tourista-app-images-box">
            <img src={image1} alt="look at the app" />
            <img src={image2} alt="look at the app" />
            <img src={image3} alt="look at the app" />
            <img src={image4} alt="look at the app" />
        </div>
    </section>
)

export default TouristaApp;