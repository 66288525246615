import { Link } from 'react-router-dom';

import appImage from '../../../assets/images/home/apps/tourista.png';

const Tourista = () => (
    <article id="TouristaHome" className="home-article home-app-tourista">
        <div className="home-article-top">
            <div className="home-article-description">
                <h2>Tourista</h2>
                <p>
                Tourista is an online tourist services company<br />
                who provide tourist custom package 
                </p>
            </div>
            <div className="home-article-more">
                <div className="home-article-more-text">
                    <h3>Desktop</h3>
                    <p>UX / UI / Research</p>
                </div>
                <Link to="/projects/tourista" className="home-app-tourista-button"><span>Case study</span><span>➜</span></Link>
            </div>
        </div>
        <img src={appImage} alt="tourista" />
    </article>
)

export default Tourista;