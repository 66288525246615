import { Link } from 'react-router-dom';

import appImage from '../../../assets/images/home/apps/pay-me.png';

const PayMe = () => (
    <article className="home-article home-app-payme">
        <div className="home-article-top">
            <div className="home-article-description">
                <h2>Payme</h2>
                <p>
                PayMe is a payment platform that<br />
                brings a new easy way to pay bill’s.<br />
                Now, all the monthly bill’s get straight<br />
                to PayMe system.
                </p>
            </div>
            <div className="home-article-more">
                <div className="home-article-more-text">
                    <h3>Cross platform </h3>
                    <p>UX / UI / Research</p>
                </div>
                <Link to="/projects/payme" className="home-app-payme-button"><span>Case study</span><span>➜</span></Link>
            </div>
        </div>
        <img src={appImage} alt="PayMe" />
    </article>
)

export default PayMe;