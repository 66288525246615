import { Link } from 'react-router-dom';

const NotFound = () => (
    <div className="not-found">
        <h2>404</h2>
        <h3>NOT FOUND</h3>
        <Link to="/">Click here to return to Home page</Link>
    </div>
)

export default NotFound;