import momentumTopImage from '../../../../assets/images/projects/momentum/projects-momentum-intro-iphone.png';

const MomentumIdea = () => (
    <section className="projects-momentum-idea">
        <div className="projects-momentum-idea-top">
            <div>
                <h2>Momentum</h2>
                <p>Mobile fitness app</p>
            </div>
            <img src={momentumTopImage} alt="Momentum Introduction" />
        </div>
        <div className="projects-momentum-idea-box projects-momentum-idea-box-a">
            <ul className="row">
                <li>
                    <h3 className="h3-green-logo">Overview</h3>
                    <p>
                    a Fitness app for workout plans.<br />
                    Its allow observing tracking and analyze<br />
                    workout plan progress.
                    </p>
                </li>
                <li>
                    <h3 className="h3-green-logo">My role</h3>
                    <p>
                    User research and competitive analysis,<br />
                    information architecture, user flow, MVP definition,<br />
                    wireframes, and UI design.
                    </p>
                </li>
            </ul>
        </div>
        <div className="projects-momentum-idea-box perfect-blue projects-momentum-idea-box-b">
            <div className="projects-momentum-idea-user-research">
                <h3 className="h3-green-logo">User Research</h3>
                <p>
                I have made a online interviews with several<br />
                potential users from my target audience.<br />
                Here are some conclusions - 
                </p>
            </div>
            <ul className="row">
                <li>
                    <h2>01</h2>
                    <p>
                    Users mentioned the <b>Observing of their workout plans is
                    not comfortable</b>, their plans are
                    usually written on paper or stored
                    in Google Keep.
                    </p>
                </li>
                <li>
                    <h2>02</h2>
                    <p>
                    Nearly half of the potential users,
                    submitted a self-review and
                    <b> tracked their progress.</b>
                    </p>
                </li>
                <li>
                    <h2>03</h2>
                    <p>
                    Many of the potential users mentioned
                    they <b>lack dynamic updates</b> during
                    their workouts. Sometimes they miss
                    an exercise or reduce a repetition which
                    situation who can harm the progress.
                    </p>
                </li>
            </ul>
        </div>
        <div className="projects-momentum-idea-box projects-momentum-idea-box-c">
            <ul className="row">
                <li>
                    <h3 className="h3-green-logo">Insights</h3>
                    <div className="column-paragraph-container">
                        <p>
                        Most of the people who took part in my interviews mentioned that they work out with a plan.
                        </p>
                        <p>
                        First point, The fact that their <b>plans are stored in different places</b> is considered an inconvenience. Users currently store plans primarily in Google Keep or on paper. 
                        </p>
                        <p>
                        The second pain point I found through potential user interviews was that people wanted for <b>self-review option</b>, a feedback tool  for enhancing their progress.
                        </p>
                    </div>
                </li>
                <li>
                    <h3 className="h3-green-logo">Value proposition</h3>
                    <p>
                    MOMENTUM is a fitness app that aims for active 
                    people who work out using a workout plan. 
                    The user can submit their workout plan to a calendar, 
                    and then easily track and review a specific exercise or 
                    the whole workout and view data and statistics.
                    </p>
                </li>
            </ul>
        </div>
        <div className="last-in-section"></div>
    </section>
)

export default MomentumIdea;