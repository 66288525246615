import avatar from '../../../../assets/images/projects/momentum/avatar.png';
import googleKeep from '../../../../assets/images/projects/momentum/google-keep.png';
import fithero from '../../../../assets/images/projects/momentum/fithero.png';
import fitnotes from '../../../../assets/images/projects/momentum/fitnotes.png';
import strengthLog from '../../../../assets/images/projects/momentum/strengthlog.png';

const MomentumAnalyzing = () => (
    <section className="projects-momentum-analyzing">
        <div className="projects-momentum-analyzing-box projects-momentum-analyzing-box-a">
            <h3 className="h3-green-logo">Persona</h3>
            <h2>
            "I've missed a platform that gave me<br />
            the ability to view my plan, update<br />
            it and track my progress dynamically"
            </h2>
            <div className="projects-momentum-analyzing-ariel">
                <div className="projects-momentum-analyzing-ariel-avatar">
                    <img src={avatar} alt="Ariel's avatar" />
                </div>
                <div className="projects-momentum-analyzing-ariel-info perfect-blue">
                    <div className="projects-momentum-analyzing-ariel-info-aspect projects-momentum-analyzing-ariel-info-aspect-general">
                        <table>
                            <tbody>
                                <tr>
                                    <th><h3>Name</h3></th>
                                    <th><h3>Age</h3></th>
                                    <th><h3>Personal status</h3></th>
                                </tr>
                                <tr>
                                    <td><p>Ariel Hilel</p></td>
                                    <td><p>22</p></td>
                                    <td><p>Discharged soldier</p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="projects-momentum-analyzing-ariel-info-aspect projects-momentum-analyzing-ariel-info-aspect-background">
                        <h3>Background</h3>
                        <p>
                        Ariel is a discharged soldier; he is 22 years old and has high 
                        digital literacy.<br />
                        Ariel's activity level is high: he runs and plays
                        soccer with his friends on weekends.<br />
                        Recently, Ariel started to visit the gym, using a notebook to
                        write notes after the workout is done.
                        </p>
                    </div>
                    <div className="projects-momentum-analyzing-ariel-info-aspect projects-momentum-analyzing-ariel-info-aspect-goals">
                        <h3>Goals</h3>
                        <ul>
                            <li>
                            <p>One platform for calendar and workout plan</p>
                            </li>
                            <li>
                            <p>Collect data, statistics, and analyze</p>
                            </li>
                            <li>
                            <p>Self-review section</p>
                            </li>
                            <li>
                            <p>Platform that enhances momentum</p>
                            </li>
                        </ul>
                    </div>
                    <div className="projects-momentum-analyzing-ariel-info-aspect projects-momentum-analyzing-ariel-info-aspect-frustration">
                        <h3>Frustrations</h3>
                        <ul>
                            <li>
                                <p>here is a sense of inconvenience with the fact that<br /> the workout plan and the notebook for reviews are separated.</p>
                            </li>
                            <li>
                                <p>Many of interviewers said self-review is crucial for<br /> moving forward and strengthening habits</p>
                            </li>
                            <li>
                                <p>While the user workout at the gym is might<br /> be complex to skip from Between the app and the other</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="projects-momentum-analyzing-box projects-momentum-analyzing-box-b">
            <h3 className="h3-green-logo">Market analysis</h3>
            <table>
                <tbody>
                    <tr>
                        <th><img src={googleKeep} alt="google keep" /></th>
                        <th><img src={fithero} alt="fithero" /></th>
                        <th><img src={fitnotes} alt="fitnotes" /></th>
                        <th><img src={strengthLog} alt="strengthlog" /></th>
                    </tr>
                    <tr>
                        <td>Google Keep</td>
                        <td>Fithero</td>
                        <td>Fitnotes</td>
                        <td>StrengthLog</td>
                    </tr>
                </tbody>
            </table>
            <h3 className="h3-thin">Conclusions</h3>
            <ol>
                <li>
                    <p>
                    The notebook which used as the main 
                    feature is not accessible and the workflow is not intuitive.
                    </p>
                </li>
                <li>
                    <p>
                    many apps lack innovative design. 
                    </p>
                </li>
                <li>
                    <p>
                    The work flow was not easily understood and 
                    it take some time to get to the right place.
                    </p>
                </li>
                <li>
                    <p>
                    Most apps do not show a place to take personal notes
                    </p>
                </li>
            </ol>
        </div>
        <div className="last-in-section"></div>
    </section>
)

export default MomentumAnalyzing;