import letsTalkImage from '../../../assets/images/home/lets-talk-image.png';

const LetsTalk = () => {
    const scrollTop = (e) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return(
        <article className="home-article home-lets-talk">
            <div className="home-article-top">
                <div className="home-article-description home-lets-talk-description">
                    <h2>Let’s Talk</h2>
                    <div className="column-paragraph-container">
                        <p>
                            Want to get to know me better ?<br />
                            I would like to hear from you ☺
                        </p>
                        <p className="home-article-description-light">
                            Ronenz0r@gmail.com<br />
                            +972-526572300
                        </p>
                    </div>
                </div>
                <div className="home-lets-talk-image">
                <img src={letsTalkImage} alt="" />
                </div>
            </div>
            <button onClick={scrollTop}>
                <span>↓</span>
                <span>Take me up</span>
            </button>
        </article>
    )
}

export default LetsTalk;