import { Link } from 'react-router-dom';

import appImage from '../../../assets/images/home/apps/momentum.png';

const Momentum = () => (
    <article id="MomentumHome" className="home-article home-app-momentum">
        <div className="home-article-top">
            <div className="home-article-description">
                <h2>Momentum</h2>
                <p>
                    a Fitness app for workout plans<br />
                    It allow observing tracking and<br />
                    analyzing workout plan progress.
                </p>
            </div>
            <div className="home-article-more">
                <div className="home-article-more-text">
                    <h3>Mobile</h3>
                    <p>UX / UI / Research</p>
                </div>
                <Link to="/projects/momentum" className="home-app-momentum-button"><span>Case study</span><span>➜</span></Link>
            </div>
        </div>
        <img src={appImage} alt="Momentum" />
    </article>
)

export default Momentum;