import { useLocation } from 'react-router-dom';

import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import hamburgerClosed from '../assets/images/hamburger-closed.png';
import hamburgerOpened from '../assets/images/hamburger-opened.png';

const Header = () => {
    const currentPath = useLocation().pathname;

    const toggleNav = e => {
        const trigger = e.currentTarget;
        const visibility = trigger.getAttribute("data-visibility");
        const nav = document.getElementsByTagName('nav')[0];

        if(visibility === 'visible'){
            trigger.setAttribute("data-visibility", "hidden");
            trigger.querySelector('.header-hamburger-closed').style.opacity = 1;
            trigger.querySelector('.header-hamburger-opened').style.opacity = 0;
            nav.classList.add("header-nav-hidden");
        }else{
            trigger.setAttribute("data-visibility", "visible");
            trigger.querySelector('.header-hamburger-closed').style.opacity = 0;
            trigger.querySelector('.header-hamburger-opened').style.opacity = 1;
            nav.classList.remove("header-nav-hidden");
        }
    }

    return(
        <header className='container-space'>
            <div className="header-logo">
                <h1><Link to="/">Ronen Televich</Link></h1>
            </div>
            <button className="header-nav-trigger" data-visibility="hidden" onClick={toggleNav}>
                <img className="header-hamburger-closed" alt="nav closed" src={hamburgerClosed} />
                <img className="header-hamburger-opened" alt="nav opened" src={hamburgerOpened} />
            </button>
            <nav className="header-nav-hidden">
                <ul>
                    <li><NavLink to="/">Home</NavLink></li>
                    <li>
                        {currentPath.startsWith('/projects') ? <NavLink to={currentPath}>Projects</NavLink> : <HashLink smooth to="/#MomentumHome">Projects</HashLink>}
                    </li>
                    <li><NavLink to="/about">About</NavLink></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header;