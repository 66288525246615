import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import './assets/css/main.css';

import Header from './components/Header';
import Home from './components/Home/Index';
import Momentum from './components/Projects/Momentum/Index';
import Payme from './components/Projects/Payme/Index';
import Tourista from './components/Projects/Tourista/Index';
import About from './components/About/Index';
import NotFound from './components/NotFound.jsx';

const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
  return(
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects/momentum" element={<Momentum />} />
        <Route path="/projects/payme" element={<Payme />} />
        <Route path="/projects/tourista" element={<Tourista />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App;
