import PaymeIdea from './Sections/PaymeIdea';
import PaymeApp from './Sections/PaymeApp';

import up from '../../../assets/images/projects/up.png';

import '../../../assets/css/projects/payme.css';

const Projects = () => {
    const scrollTop = (e) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return(
        <div className="projects">
            <div className="projects-momentum">
                <PaymeIdea />
                <PaymeApp />
            </div>
            <div className="projets-scroll-top-button">
                <button onClick={scrollTop}>
                    <h2>Thanks for your time</h2>
                    <img src={up} alt="scroll to top" />
                </button>
            </div>
        </div>
    )
}

export default Projects;